.home-two-body-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid black; */
}

.home-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #242B32;
    padding: 0;
    padding-top: 29px;
    margin: 0;
}

.item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 43px 0;
}

.image {
    aspect-ratio: 1/1;
    max-inline-size: 100%;
    block-size: auto;
}

.item-text {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
}

.text {
    text-align: center;
    margin: 0;
    /* width: 232px; */
}

.right-arrow-svg {
    position: relative;
    right: -10px;
    transition: transform 0.3s ease-in-out;
}

.text-underline {
    width: 100%;
    min-width: 100%;
    height: 2px;
    background: #674736;
}

.home-two-body-item:hover .right-arrow-svg {
    transform: translateX(5px);
}

@media (min-width: 600px) and (max-width: 920px) {
    .item-text .text {
        width: 100%;
    }

    .right-arrow-svg {
        right: -20px;
    }

    @media (max-width: 760px) {
        .right-arrow-svg {
            right: 0;
        }
    }
}
