  /* Breadcrumb */

  .breadcrumb {
    display: flex;
    align-items: center;
    gap: .5em;
  }

  .breadcrumb p {
    margin: 0;
    font-size: 13px;
  }

  .former-state {
    font-size: 13px;
    color: #3d4647;
    opacity: .9;
  }

  .current-state{
    color: #3D4647;
    font-weight: bolder;
  }
