/* Default Styles */
.contact-container {
    margin-top: 44px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.breadcrumb {
    display: none;
}

.body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 42px;
    width: 100%;
    margin-bottom: 16px;
}

.header-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 6px;
}

.header {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 102%;
}

.header2 {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
    color: rgba(61, 70, 71, 0.75);
}

.numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    text-align: right;
}

.numbers-left {
    display: flex;
    flex-direction: column;
    gap: 18px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%;
}

.numbers-right {
    display: flex;
    flex-direction: column;
    gap: 18px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
}

.email-container {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 7px 0;
    background: #734F38;
    color: #FFF;
    border: none;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 151%;
    transition: background 0.3s ease;
}

.button:hover {
    background: #855B41;
}

.button:active {
    background: white;
    color: #734F38;
}

.email {
    color: rgba(36, 43, 50, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
}

.dash {
    margin: 25px 0 4px;
    width: 40px;
    height: 2px;
    background: #674736;
}

.location-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.location {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%;
}

.address-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 3px;
}

.address {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
}

.contact-svg {
    min-width: 19px;
    height: 19px;
}

/* Media Query Styles for 415px to 920px */
@media (min-width: 415px) and (max-width: 920px) {
    .contact-container {
        padding: 0 49px;
    }

    .body-container {
        gap: 51px;
    }

    .header-container .header {
        font-size: 48px;
    }

    .email-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }

    .email-container .button {
        max-width: 180px;
    }

    .dash {
        margin: 0;
    }

    .location-container {
        gap: 8px;
    }
}

/* Media Query Styles for 920px and above */
@media (min-width: 920px) {
    .contact-container {
        padding: 0 60px;
    }

    .breadcrumb {
        display: flex;
        width: 100%;
        margin-bottom: 63px;
    }

    .body-container {
        gap: 47px;
    }

    .header-container {
        gap: 9px;
    }

    .header-container .header {
        font-size: 48px;
    }

    .header-container .header2 {
        font-size: 20px;
    }

    .numbers {
        text-align: left;
    }

    .email-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }

    .email-container .button {
        max-width: 188px;
    }

    .dash {
        margin: 19px 0 9px;
    }

    .location-container {
        gap: 18px;
    }

    .address-container {
        gap: 6px;
    }

    .address-container .address {
        font-size: 20px;
    }
}

/* Media Query Styles for 920px and above */
@media (min-width: 920px) {
    .contact {
        display: flex;
        justify-content: center;
    }

    .contact-container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
}
