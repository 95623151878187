/* Styles for .about-container */
.about-container {
    margin-top: 44px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 415px) and (max-width: 920px) {
    .about-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .about-container {
        padding: 0 60px;
    }
}

.breadcrumb {
    display: none;
}

.about-header {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 102%;
    margin-bottom: 20px;
}

.about-body {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.about-text {
    color: var(--text, #3D4647);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 102%;
    letter-spacing: 0.08px;
}

.text-link {
    text-decoration: none;
    color: #1380E4;
}

.about-dash {
    margin: 80px 0 70px;
    width: 40px;
    height: 2px;
    background: #674736;
}

@media (min-width: 415px) and (max-width: 920px) {
    .about-header {
        text-align: center;
        font-size: 48px;
    }

    .about-text {
        font-size: 17px;
    }
}

@media (min-width: 920px) {
    .breadcrumb {
        display: flex;
        width: 100%;
    }

    .about-header {
        font-size: 48px;
        margin-bottom: 29px;
    }

    .about-body {
        max-width: 658px;
        text-align: center;
    }

    .about-text {
        font-size: 20px;
    }

    .about-dash {
        margin: 71px 0 51px;
        width: 40px;
        height: 2px;
        background: #674736;
    }
}

/* Styles for .about-image */
.about-image {
    margin-bottom: -10px;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: contain;
    object-position: bottom center;
}

@media (min-width: 415px) and (max-width: 920px) {
    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 117px;
    }

    .about-image {
        max-width: 438px;
        max-height: 288px;
    }
}

@media (min-width: 920px) {
    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 117px;
    }

    .about-image {
        max-width: 547px;
        max-height: 360px;
    }

    .about {
        display: flex;
        justify-content: center;
    }

    .about-container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
}
