/* Styles for .menu-container */
.menu-container {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    background: white;
    box-shadow: -5px 0 5px -2px rgba(200, 200, 200, 0.5);
}

.menu-header {
    display: flex;
    justify-content: end;
    padding: 33px 44px;
}

.menu-body {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 0 55px;
    margin-top: 100px;
}

.menu-body-categories {
    display: flex;
    align-items: center;
    gap: 3px;
}

.menu-body-text {
    cursor: pointer;
    text-decoration: none;
    color: #242B32;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

/* Media Query styles */
@media (min-width: 415px) and (max-width: 920px) {
    .menu-container {
        width: 55%;
    }

    .menu-header {
        padding: 42px 44px;
    }

    .menu-body {
        padding: 0 114px;
        margin-top: 180px;
    }
}

@media (min-width: 920px) {
    .menu-container {
        display: flex;
        position: relative;
        z-index: 1;
    }

    .menu-header {
        display: flex;
        justify-content: end;
        padding: 33px 44px;
    }

    .menu-body {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 0 55px;
        margin-top: 100px;
    }

    .menu-body-text {
        cursor: pointer;
        text-decoration: none;
        color: #242B32;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
    }
}
