/* Styles for .search-container */
.search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 34px;
    outline: 0.5px solid #674736;
    transition: box-shadow 0.3s ease;
}

.search-input-container {
    width: 100%;
    padding: 9px 0 9px 17px;
}

.search-input {
    border: none;
    outline: none;
    width: 100%;
}

.search-svg {
    fill: white;
    background: #674736;
    padding: 3px 6px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.search-container:hover {
    outline: 2px solid #674736;
}

/* Media Query styles */
@media (min-width: 605px) and (max-width: 920px) {
    .search-input-container {
        width: 100%;
        padding: 9px 0 9px 17px;
    }
}

@media (min-width: 920px) {
    .search-container {
        max-height: 48px;
    }

    .search-input-container {
        width: 100%;
        padding: 12px 0 12px 17px;
    }

    .search-svg {
        fill: white;
        background: #674736;
        padding: 7px 10px;
        width: 30px;
        height: 30px;
    }
}

@media (min-width: 920px) and (max-width: 1327px) {
    .search-input-container {
        padding: 12px 0 12px 17px;
    }
}

@media (min-width: 920px) and (max-width: 1000px) {
    .search-input-container {
        padding: 12px 0 12px 17px;
    }
}
