/* Styles for nav */
nav {
    width: 100%;
}

/* Styles for .brown-bar */
.brown-bar {
    background: #674736;
}

.brown-container {
    display: flex;
    justify-content: end;
    padding: 15px 30px;
}

.brown-bar-right,
.brown-bar-left {
    display: flex;
    gap: 4px;
}

.brown-bar-svg {
    width: 13px;
    height: 13px;
}

.brown-bar-text {
    margin: 0;
    color: white;
    font-size: 13px;
    font-weight: 500;
}

.brown-bar-left {
    display: none;
}

.brown-bar-right {
    cursor: pointer;
    align-items: center;
}

@media (min-width: 415px) and (max-width: 605px) {
    .brown-container {
        padding: 15px 49px;
    }

    .brown-bar-right {
        cursor: default;
        align-items: center;
    }
}

@media (min-width: 605px) and (max-width: 920px) {
    .brown-container {
        gap: 24px;
        padding: 15px 49px;
    }

    .brown-bar-left {
        display: flex;
        gap: 4px;
    }

    .brown-bar-right {
        cursor: default;
        align-items: center;
    }
}

@media (min-width: 920px) {
    .brown-container {
        width: 100%;
        max-width: 1440px;
        gap: 24px;
        padding: 15px 60px;
    }

    .brown-bar-left {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .brown-bar-right {
        cursor: default;
        align-items: center;
    }
}

@media (min-width: 920px) {
    .brown-bar {
        display: flex;
        justify-content: center;
    }
}

/* Styles for .nav-bar */
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
}

.logo-text {
    display: none;
}

.nav-right-container {
    display: flex;
    gap: 16px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .nav-container {
        padding: 35px 49px;
    }

    .nav-right-container .searchbar {
        /* Change the width of the search bar here for tablet */
        /* min-width: 400px */
        width: auto;
    }
}

@media (min-width: 920px) {
    .nav-container {
        padding: 37px 60px;
        width: 100%;
        max-width: 1440px;
    }

    .logo {
        display: flex;
        align-items: center;
        gap: 12px;
        text-decoration: none;
    }

    .logo-text {
        display: flex;
        font-size: 28px;
        font-weight: 600;
        color: #242B32;
    }

    .nav-right-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 65%;
    }

    .nav-right-container .searchbar {
        min-width: 400px;
    }

    .menubar .normal-menu {
        display: flex;
        align-items: center;
        min-height: 40px;
        gap: 24px;
        cursor: pointer;
    }

    .menubar .normal-menu .normal-menu-text {
        text-decoration: none;
        color: #242B32;
        font-size: 13px;
        font-weight: 500;
    }

    .menubar #normal-menu-cat {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    @media (min-width: 920px) and (max-width: 1108px) {
        .nav-right-container {
            width: 92%;
        }
    }

    @media (min-width: 1108px) and (max-width: 1415px) {
        .nav-right-container {
            width: 75%;
        }
    }

    @media (max-width: 1220px) {
        .logo-text {
            display: none;
        }
    }
}

@media (min-width: 920px) {
    .nav-bar {
        display: flex;
        justify-content: center;
    }
}
