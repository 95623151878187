/* tablet */
@media only screen and (max-width: 1000px) and (min-width: 415px) {
    .lost-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

    }

    .lost-page-header-container {
        display: flex;
        justify-content: space-between;
        /* border: 1px solid black; */
        height: 15rem;
        width: 500px;

    }

    .lost-page-num,
    .lost-page-num-right,
    .lost-page-text {
        font-style: normal;
        font-weight: 700;
        font-size: 200px;
        line-height: 151%;
        color: #734F38;
    }

    .lost-page-num-right {
        margin-left: 20px;
        transform: rotateZ(34.59deg)
    }

    .lost-page-text {
        font-size: 30px;
    }

    .lost-image {
        height: 500px;
        position: relative;
        bottom: 7rem;
        left: 15.5rem;
    }



    .lost-brown-button {
        height: 2.75rem;
        width: 14rem;
        color: white;
        border: none;
        background: #734F38;
        font-weight: 500;
        font-size: 22px;
        line-height: 151%;
        letter-spacing: 0.005em;
        cursor: pointer;
        text-decoration: none;
        margin-top: -7rem;
        margin-bottom: 11.8rem;
    }
}


/* Media Quries -- desktop */
@media only screen and (min-width: 1000px) {
    .lost-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

    }

    .lost-page-header-container {
        display: flex;
        justify-content: space-between;
        /* border: 1px solid black; */
        height: 23rem;
        width: 720px;

    }

    .lost-page-num,
    .lost-page-num-right,
    .lost-page-text {
        font-style: normal;
        font-weight: 700;
        font-size: 300px;
        line-height: 151%;
        color: #734F38;
    }

    .lost-page-num-right {
        margin-left: 20px;
        transform: rotateZ(34.59deg)
    }

    .lost-page-text {

        font-size: 36px;

    }

    .lost-image {
        position: relative;
        bottom: 5rem;
        left: 20rem;
    }



    .lost-brown-button {
        height: 3.5rem;
        width: 14rem;
        color: white;
        border: none;
        background: #734F38;
        font-weight: 500;
        font-size: 22px;
        line-height: 151%;
        letter-spacing: 0.005em;
        cursor: pointer;
        text-decoration: none;
        margin-top: -5rem;
        margin-bottom: 7.188rem;
    }
}


/* mobile */
@media only screen and (max-width: 415px) {
    .lost-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 90%;
        margin-top: 1rem;

    }

    .lost-page-header-container {
        display: flex;
        justify-content: space-between;
        /* border: 1px solid black; */
        width: 85%;

    }

    .lost-page-num,
    .lost-page-num-right,
    .lost-page-text {
        font-style: normal;
        font-weight: 700;
        font-size: 140px;
        line-height: 151%;
        color: #734F38;
    }

    .lost-page-num-right {
        margin-left: 10px;
        transform: rotateZ(34.59deg)
    }

    .lost-page-text {
        font-size: 30px;
        margin-top: -2rem;
    }

    .lost-image {
        position: relative;
        height: 18.5rem;
        width: 17rem;
        bottom: 3.5rem;
        left: 10.4rem;
    }


    .lost-brown-button {
        height: 3rem;
        width: 95%;
        color: white;
        border: none;
        background: #734F38;
        font-weight: 500;
        font-size: 24px;
        line-height: 151%;
        letter-spacing: 0.005em;
        cursor: pointer;
        text-decoration: none;
        margin-top: -1rem;
        margin-bottom: 8rem;
    }
}
