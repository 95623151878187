.item-container {
    margin: 44px 0 152px;
    padding: 0 30px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .item-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .item-container {
        padding: 0 60px;
    }
}

.item-container .breadcrumb {
    display: none;
}

.item-container .item-header-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 44px;
}

.item-container .item-header-container .item-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.item-container .item-header-container .item-header-two {
    color: #3D4647;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 102%;
}

.item-container .item-body-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

@media (min-width: 500px) and (max-width: 1220px) {
    .item-container .item-header-container {
        margin-bottom: 40px;
    }

    .item-container .item-header-container .item-title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
    }

    .item-container .item-header-container .item-header-two {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

    .item-container .item-body-container {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-bottom: 184px;
    }

    .item-container .item-button-container {
        margin-top: 70px;
    }

    .item-container .item-button-container .item-button {
        padding: 10px 0;
        font-size: 19px;
        max-width: 190px;
    }
}

@media (min-width: 1220px) {
    .item-container .breadcrumb {
        display: flex;
        margin-bottom: 99px;
    }

    .item-container .item-header-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 53px;
    }

    .item-container .item-header-container .item-title {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 102%;
    }

    .item-container .item-header-container .item-header-two {
        font-size: 22px;
    }

    .item-container .item-body-container {
        display: flex;
        flex-direction: row;
        gap: 0;
        margin-bottom: 270px;
    }

    .item-container .item-button-container {
        display: flex;
        justify-content: center;
        margin-top: 0;
    }

    .item-container .item-button-container .item-button {
        padding: 12px 0;
        font-size: 19px;
        max-width: 223px;
        margin: 0;
    }
}

@media (min-width: 920px) {
    .item {
        display: flex;
        justify-content: center;
    }

    .item-container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
}
