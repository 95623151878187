.home-container {
    margin-top: 37px;
    padding: 0 30px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .home-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .home-container {
        padding: 0 170px;
    }
}

.home-one-container {
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.one-left {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.one-left-header {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

.one-header-h2 {
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    line-height: 3rem;
    letter-spacing: -0.06rem;
}

.one-header-h4 {
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    line-height: 151%;
    letter-spacing: -0.02063rem;
}

.one-left-body {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
}

.one-left-body-text {
    margin: 0;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.65rem;
    letter-spacing: 0.03rem;
}

.button-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.button-a {
    cursor: pointer;
}

.brown-button {
    background: #734F38;
    border: 2px solid #734F38;
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
}

.white-button {
    background: white;
    border: 2px solid #734F38;
    color: #734F38;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 0;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
}

.brown-button:hover {
    background: #855B41;
    border: 2px solid #855B41;
}

.brown-button:active {
    background: white;
    border: 2px solid #734F38;
    color: #734F38;
}

.white-button:hover {
    background: #855B41;
    color: white;
    border: 2px solid #855B41;
}

.white-button:active {
    background: #734F38;
    border: 2px solid #734F38;
    color: white;
}

.one-right {
    display: none;
}

@media (min-width: 415px) and (max-width: 920px) {
    .home-one-container {
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .one-left {
        width: 58%;
    }

    .one-left-header .one-header-h2 {
        font-size: 48px;
    }

    .one-left-body {
        row-gap: 74px;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    @media (max-width: 800px) {
        .button-container {
            flex-direction: column;
        }
    }

    .brown-button {
        min-height: 48px;
        min-width: 198px;
        font-size: 19px;
        padding: 10px 13px;
    }

    .white-button {
        min-height: 48px;
        min-width: 198px;
        font-size: 19px;
        padding: 10px 58px;
    }
}

@media (min-width: 920px) {
    .home-one-container {
        display: flex;
        justify-content: space-between;
        margin: 84px 0 193px;
        width: 100%;
        max-width: 1125px;
        margin-bottom: 110px;
        text-align: left;
    }

    .one-left {
        width: 100%;
        max-width: 475px;
    }

    .one-left-header .one-header-h2 {
        font-size: 64px;
        line-height: 4.4rem;
        letter-spacing: -0.06rem;
    }

    .one-left-header .one-header-h4 {
        font-size: 22px;
        font-weight: 700;
    }

    .one-left-body {
        display: flex;
        flex-direction: column;
        row-gap: 64px;
    }

    .one-left-body-text {
        font-size: 22px;
        margin-top: 10px;
        line-height: 1.925rem;
        letter-spacing: 0.04125rem;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        margin-top: 7px;
    }

    .brown-button {
        min-width: 220px;
        min-height: 50px;
        font-size: 22px;
        padding: 20px 16px;
    }

    .white-button {
        min-width: 220px;
        min-height: 50px;
        font-size: 22px;
        padding: 20px 69px;
    }

    .one-right {
        display: block;
        width: 499px;
    }

    @media (max-width: 1200px) {
        .one-right {
            display: none;
        }
    }
}


/* HEADER 3 */
.home-h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    line-height: 102%;
    letter-spacing: -0.04688rem;
    text-align: center;
}

@media (min-width: 415px) and (max-width: 920px) {
    .home-h3 {
        font-size: 36px;
        font-weight: 700;
    }
}

@media (min-width: 920px) {
    .home-h3 {
        font-size: 50px;
        font-weight: 700;
        text-align: left;
    }
}

/* SECTION 2  */
.home-two-container {
    margin-bottom: 118px;
}

.home-two-body {
    display: flex;
    flex-direction: column;
    row-gap: 44px;
    padding: 0 23px;
    margin-top: 44px;
}

@media (min-width: 600px) and (max-width: 1100px) {
    .home-two-container {
        width: 100%;
        margin-bottom: 123px;
    }
    
    .home-two-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 84px;
        padding: 0;
        margin-top: 76px;
    }
}

@media (min-width: 515px) and (max-width: 675px) {
    .home-two-body {
        gap: 20px;
    }
}

@media (max-width: 515px) {
    .home-two-body {
        gap: 40px;
    }
}

@media (min-width: 1100px) {
    .home-two-container {
        width: 100%;
        max-width: 1125px;
        margin-bottom: 125px;
    }
    
    .home-two-body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 135px;
        row-gap: 79px;
        padding: 0;
        margin-top: 76px;
    }
}

@media (min-width: 1200px) and (max-width: 1320px) {
    .home-two-body {
        column-gap: 75px;
    }
}

@media (min-width: 920px) and (max-width: 1200px) {
    .home-two-body {
        column-gap: 30px;
    }
}


/* Section 3 */

.home-three-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 79px;
}

.home-three-left {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    gap: 24px;
}

.home-three-h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.home-three-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.home-three-nav {
    text-decoration: none;
    color: var(--text, #1380E4);
}

.home-three-right {
    margin-top: 38px;
    text-align: center;
}

@media (min-width: 415px) and (max-width: 920px) {
    .home-three-container {
        margin-bottom: 84px;
    }

    .home-three-left {
        margin-top: 27px;
        gap: 20px;
    }

    .home-three-h5 {
        font-size: 20px;
        font-weight: 700;
    }

    .home-three-text {
        font-size: 18px;
    }

    .home-three-right {
        margin-top: 40px;
        opacity: 0.6;
    }
}

@media (min-width: 920px) {
    .home-three-container {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 175px;
        width: 100%;
        max-width: 1125px;
    }

    .home-three-left {
        width: 60%;
        gap: 13px;
        margin-top: 27px;
    }

    .home-three-h5 {
        font-size: 20px;
        margin-top: 10px;
    }

    .home-three-right {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        margin-top: 40px;
        opacity: 0.6;
        gap: 15px;
    }

    .world-svg {
        width: 142px;
        height: 143px;
    }

    .person-svg {
        width: 46px;
        height: 61px;
    }
}

@media (min-width: 920px) {
    .home {
        display: flex;
        justify-content: center;
    }

    .home-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1440px;
        margin: 0;
    }
}
