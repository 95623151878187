/* Styles for .cat-dropdown-container */
.cat-dropdown-container {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    width: 85%;
    background: white;
    box-shadow: -5px 0 5px -2px rgba(200, 200, 200, 0.5);
    overflow-y: scroll;
}

.cat-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29px 44px 25px 18px;
    border-bottom: 2px solid #674736;
}

.cat-dropdown-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cat-dropdown-header-left-text {
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
}

.cat-dropdown-body {
    display: flex;
    flex-direction: column;
    padding: 21px 26px 108px;
    row-gap: 36px;
}

.cat-dropdown-col {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}

.cat-dropdown-category-header {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.cat-dropdown-category-text {
    color: var(--text, #3D4647);
    font-size: 13px;
    font-weight: 400;
}

/* Media Query styles */
@media (min-width: 415px) and (max-width: 920px) {
    .cat-dropdown-container {
        width: 55%;
    }

    .cat-dropdown-header {
        padding: 39px 44px 32px 36px;
    }

    .cat-dropdown-header-left {
        gap: 12px;
    }

    .cat-dropdown-body {
        padding: 37px 44px 108px;
    }
}

@media (min-width: 920px) {
    .cat-dropdown {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        width: 100%;
        background: white;
        left: 0;
        top: 200px;
        max-height: 288px;
        box-shadow: 0 5px 5px -2px rgba(200, 200, 200, 0.5), 0 -5px 5px -2px rgba(200, 200, 200, 0.5);
    }

    .cat-dropdown-container {
        position: relative;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        max-width: 1440px;
        height: 100%;
        overflow: hidden;
        background: white;
        box-shadow: none;
        padding: 0 60px;
    }

    .cat-dropdown-header {
        display: none;
    }

    .cat-dropdown-body {
        display: flex;
        flex-direction: row;
        padding: 38px 0 41px;
        column-gap: 30px;
    }

    .cat-dropdown-category-header {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
    }

    .cat-dropdown-category-text:hover {
        text-decoration: underline;
    }

    @media (max-width: 1435px) {
        .cat-dropdown-category-header {
            min-height: 36px;
        }
    }
}
