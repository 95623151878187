.bearingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100%;

    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 1/1;
    object-fit: cover;

}
