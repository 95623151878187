.pl-container {
    padding: 0 30px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .pl-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .pl-container {
        padding: 0 60px;
    }
}

.pl-body {
}

.pl-header-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 34px;
}

.pl-header-title {
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
}

.pl-header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid #242B32;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 7px 0;
    width: 100%;
    text-decoration: none;
}

.pl-button {
    cursor: pointer;
    display: flex;
    gap: 4px;
    background: transparent;
    border: none;
}

.download-svg {
    width: 11px;
    height: 11px;
}

.pl-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px; 
}

.scroll-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 79px 0 93px;
}

@media (min-width: 600px) and (max-width: 920px) {
    .pl-header-title {
        font-size: 56px;
    }
    .pl-header-button {
        max-width: 168px;
    }
    .pl-item-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 700px) and (max-width: 920px) {
        .pl-item-container {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    .scroll-top {
        margin: 70px 0 88px;
    }
}

@media (min-width: 920px) {
    .pl-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 100px 0 128px;
    }
    .pl-header-title {
        font-size: 64px;
    }
    .pl-header-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: white;
        border: 1px solid #242B32;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        padding: 10px 0;
        width: 100%;
        max-width: 232px;
        max-height: 48px;
    }
    .pl-item-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 60px;
        grid-row-gap: 140px;
    }
    .scroll-top {
        margin: 91px 0 71px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
    }
}

@media (min-width: 920px) {
    .pl {
        display: flex;
        justify-content: center;
    }
    .pl-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
    .pl-body {
        max-width: 1440px;
        width: 100%;
    }
}
