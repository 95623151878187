.item-body-header {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 9px;
    line-height: 151%;
    letter-spacing: 0.11px;
}

.item-body {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 26px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 14px;
    line-height: 151%;
    letter-spacing: 0.085px;
}

.column-key {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    min-height: 21px;
    line-height: 151%;
    letter-spacing: 0.09px;
}

.column-value {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    min-height: 21px;
    line-height: 151%;
    letter-spacing: 0.09px;
}

.ref-data {
    order: -1;
}

.item-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-image-container .item-image {
    aspect-ratio: 1/1;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: contain;
}

.item-button-container {
    display: flex;
    justify-content: center;
    margin-top: 55px;
}

.item-button-container .item-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #734F38;
    text-decoration: none;
    padding: 7px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    line-height: 151%;
    letter-spacing: 0.11px;
}

.item-button-container .item-button:hover {
    background: #855B41;
}

.item-button-container .item-button:active {
    background: white;
    color: #734F38;
}

@media (min-width: 500px) and (max-width: 1220px) {
    .item-body {
        gap: 34px;
    }

    .column {
        gap: 9px;
    }

    .ref-data {
        order: 0;
        margin-bottom: 40px;
    }

    .item-image-container {
        position: relative;
    }

    .item-image-container .item-image {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 174px;
    }

    @media (min-width: 500px) and (max-width: 600px) {
        .item-image-container .item-image {
            min-width: 140px;
        }
    }

    .item-button-container {
        margin-top: 70px;
    }

    .item-button-container .item-button {
        padding: 10px 0;
        font-size: 19px;
        max-width: 190px;
    }
}

@media (min-width: 1220px) {
    .item-body {
        gap: 32px;
        margin-bottom: 98px;
    }

    .column {
        gap: 8px;
    }

    .column-key {
        font-size: 18px;
        font-weight: 500;
        min-height: 21px;
    }

    .column-value {
        font-size: 18px;
    }

    .ref-data {
        display: flex;
        order: -1;
    }

    .ref-data .ref-data-container {
        margin-right: 119px;
    }

    .product-data {
        margin-right: 94px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
    }

    .item-body-stick {
        margin: 5px 15px 0 0;
        width: 1px;
        height: 100%;
        background: #674736;
    }

    .item-image-container {
        align-items: flex-start;
        order: 1;
    }

    .item-image-container .item-image {
        min-width: 268px;
    }

    .item-button-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        max-width: 223px;
    }

    .item-button-container .item-button {
        padding: 12px 0;
        font-size: 19px;
        margin: 0;
    }
}
