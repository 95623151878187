.sub-container {
    margin: 44px 0 160px;
    padding: 0 30px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .sub-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .sub-container {
        padding: 0 60px;
    }
}

.breadcrumb {
    display: none;
}

.sub-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 44px;
}

.title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.subheader-text {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.sub-subheader {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 49px;
}

.sub-body-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.sub-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000;
    text-decoration: none;
    border: 1px solid black;
    padding: 10px;
}

.sub-item:nth-child(4n+1), .sub-item:nth-child(4n+2) {
    background-color: #ede3de;
    color: #000000;
}

.sub-item:nth-child(4n+3), .sub-item:nth-child(4n+4) {
    background-color: white;
    color: #000000;
}

@media (min-width: 415px) and (max-width: 815px) {
    .sub-header {
        margin-bottom: 54px;
    }

    .title {
        font-size: 48px;
    }

    .subheader-text {
        font-size: 17px;
    }

    .sub-subheader {
        margin-bottom: 32px;
    }

    .sub-body-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 212px;
    }

    .sub-item {
        padding: 20px;
    }

    .sub-item:nth-child(6n+1), .sub-item:nth-child(6n+2), .sub-item:nth-child(6n+3) {
        background-color: #ede3de;
        color: #000000;
    }

    .sub-item:nth-child(6n+4), .sub-item:nth-child(6n+5), .sub-item:nth-child(6n+6) {
        background-color: white;
        color: #000000;
    }
}

@media (min-width: 815px) and (max-width: 1115px) {
    .breadcrumb {
        display: flex;
        margin-bottom: 99px;
    }

    .sub-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 44px;
    }

    .title {
        font-size: 64px;
    }

    .subheader-text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
    }

    .sub-subheader {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 29px;
    }

    .sub-body-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 212px;
    }

    .sub-item {
        padding: 20px;
    }

    .sub-item:nth-child(8n+1), .sub-item:nth-child(8n+2), .sub-item:nth-child(8n+3), .sub-item:nth-child(8n+4) {
        background-color: #ede3de;
        color: #000000;
    }

    .sub-item:nth-child(8n+5), .sub-item:nth-child(8n+6), .sub-item:nth-child(8n+7), .sub-item:nth-child(8n+8) {
        background-color: white;
        color: #000000;
    }
}


@media (min-width: 1115px) and (max-width: 1440px) {
    .breadcrumb {
        display: flex;
        margin-bottom: 99px;
    }
    .sub-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 44px;
    }
    .title {
        font-size: 64px;
    }
    .subheader-text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
    }
    .sub-subheader {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 29px;
    }      
    .sub-body-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-bottom: 387px;
    }
    .sub-item {
        padding: 20px; 
    }
    .sub-item:nth-child(10n+1), .sub-item:nth-child(10n+2), .sub-item:nth-child(10n+3), .sub-item:nth-child(10n+4), .sub-item:nth-child(10n+5) {
        background-color: #ede3de;
        color: #000000;
    }
    .sub-item:nth-child(10n+6), .sub-item:nth-child(10n+7), .sub-item:nth-child(10n+8), .sub-item:nth-child(10n+9), .sub-item:nth-child(10n+10) {
        background-color: white;
        color: #000000;
    }
}

@media (min-width: 1440px) {
    .breadcrumb {
        display: flex;
        margin-bottom: 99px;
    }
    .sub-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 44px;
    }
    .title {
        font-size: 64px;
    }
    .subheader-text {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
    }
    .sub-subheader {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 29px;
    }
    .sub-body-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        margin-bottom: 387px;
    }
    .sub-item {
        padding: 20px; 
    }
    .sub-item:nth-child(12n+1), .sub-item:nth-child(12n+2), .sub-item:nth-child(12n+3), .sub-item:nth-child(12n+4), .sub-item:nth-child(12n+5), .sub-item:nth-child(12n+6) {
        background-color: #ede3de;
        color: #000000;
    }
    .sub-item:nth-child(12n+7), .sub-item:nth-child(12n+8), .sub-item:nth-child(12n+9), .sub-item:nth-child(12n+10), .sub-item:nth-child(12n+11), .sub-item:nth-child(12n+12) {
        background-color: white;
        color: #000000;
    }
}

@media (min-width: 920px) {
    .sub {
        display: flex;
        justify-content: center;
    }
    .sub-container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
}
