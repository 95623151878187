.pl-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    min-height: 8.3125rem;
}

.pl-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 24px;
}

.pl-image {
    aspect-ratio: 1/1;
    max-inline-size: 60%;
    block-size: auto;
    object-fit: contain;
}

.pl-item-text {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 18px;
    min-height: 34px;
}

.pl-text-underline {
    height: 2px;
    width: 100%;
    background: #6E5241;
}

@media (min-width: 415px) and (max-width: 920px) {
    /* No styles defined for this media query */
}

@media (min-width: 920px) {
    .pl-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: 12.9375rem;
    }

    .pl-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px 24px;
    }

    .pl-image {
        min-height: 180px;
        max-inline-size: 100%;
        block-size: auto;
    }

    .pl-item-text {
        font-size: 18px;
        min-height: 34px;
    }
}
