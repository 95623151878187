/* Media Quries -- desktop */
@media only screen and (min-width: 1000px) {
    .not-found-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 10.688rem;
    }

    .not-found-page-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45%;
        margin-bottom: 8rem;
    }

    .not-found-page-header {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 151%;
        color: #242B32;
        width: 100%;
        max-width: 720px;
        margin-bottom: 1rem;
    }

    .not-found-page-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 151%;
        color: #3D4647;
        max-width: 720px;

    }


    .not-found-button-container {
        display: flex;
        justify-content: center;
    }


    .not-found-brown-button {
        height: 3.5rem;
        width: 14rem;
        color: white;
        border: none;
        background: #734F38;
        font-weight: 500;
        font-size: 19px;
        line-height: 151%;
        letter-spacing: 0.005em;
        margin-right: 2rem;
        cursor: pointer;
        text-decoration: none;
    }

    .not-found-white-button {
        height: 3.5rem;
        width: 14rem;
        color: #734F38;
        border: 2px solid #734F38;
        background: transparent;
        font-weight: 500;
        font-size: 20px;
        line-height: 151%;
        letter-spacing: 0.005em;
        cursor: pointer;

    }

    .not-found-image {
        margin-top: 2.17rem;
        object-fit: contain;
        mix-blend-mode: darken;
        /* height: 386.16px;
    width: 163.67px; */

    }
}

/* tablet */
@media only screen and (max-width: 1000px) and (min-width: 415px) {
    .not-found-page-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin-top: 5.5rem;
    }

    .not-found-page-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin-bottom: 7rem;
    }

    .not-found-page-header {
        color: var(--h-1, #242B32);
        font-size: 1.375rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 151%;
        width: 100%;
    }

    .not-found-page-text {
        color: #3D4647;
        font-size: 1.0625rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 400;
        line-height: 151%;

    }


    .not-found-button-container {
        display: flex;
        justify-content: center;
    }


    .not-found-brown-button {
        width: 13.9375rem;
        height: 2.75rem;
        flex-shrink: 0;
        border: none;
        background: #734F38;

        color: #FFF;
        font-size: 1.375rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 151%;
        letter-spacing: 0.00688rem;
        margin-right: 2rem;
        cursor: pointer;
        text-decoration: none;
    }

    .not-found-white-button {
        width: 13.9375rem;
        height: 2.75rem;
        flex-shrink: 0;
        border: 2px solid #734F38;
        background: transparent;

        color: #734F38;
        font-size: 1.375rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 151%;
        letter-spacing: 0.00688rem;
        cursor: pointer;

    }

    .not-found-image {
        margin-top: 1.25rem;
        object-fit: contain;
        mix-blend-mode: darken;
        margin-bottom: 3rem;
    }
}


/* mobile */
@media only screen and (max-width: 415px) {
    .not-found-page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 90%;
        margin-top: 4.5rem;
    }

    .not-found-page-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 6.21rem;
    }

    .not-found-page-header {
        color: var(--h-1, #242B32);
        font-size: 1.375rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 151%;

        width: 100%;
        margin-bottom: 2rem;
    }

    .not-found-page-text {
        color: #3D4647;
        font-size: 1rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 700;
        line-height: 151%;

    }


    .not-found-button-container {
        display: flex;
        flex-direction: column;
    }


    .not-found-brown-button {
        height: 2.75rem;
        flex-shrink: 0;
        width: 100%;
        border: none;
        background: #734F38;
        margin-right: 2rem;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 0.81rem;

        color: #FFF;
        font-size: 1.25rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 151%;
        letter-spacing: 0.00625rem;
    }

    .not-found-white-button {
        height: 2.75rem;
        flex-shrink: 0;
        width: 100%;
        border: 2px solid #734F38;
        cursor: pointer;
        background: transparent;


        color: #734F38;
        font-size: 1.25rem;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 151%;
        letter-spacing: 0.00625rem;
    }

    .not-found-image {
        position: relative;
        height: 30rem;
        width: 30rem;
        left: -3.5rem;
        margin-top: 0.5rem;
        object-fit: contain;
        mix-blend-mode: darken;
        margin-bottom: 6rem;
    }
}
