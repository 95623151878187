/* Styles for .category-container */
.category-container {
    margin-top: 44px;
    padding: 0 30px;
}

@media (min-width: 415px) and (max-width: 920px) {
    .category-container {
        padding: 0 49px;
    }
}

@media (min-width: 920px) {
    .category-container {
        padding: 0 60px;
    }
}

.breadcrumb {
    display: none;
}

.category-header {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 36px;
}

.category-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 46px;
    margin-bottom: 175px;
}

.category-item {
    min-height: 229px;
}

.category-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 51px 77px 36px;
}

.category-image {
    max-height: 150px;
    aspect-ratio: 1/1;
    max-inline-size: 100%;
    block-size: auto;
    object-fit: contain;
}

.category-item-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
}

.category-text {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 0;
    width: 232px;
}

.category-svg {
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.category-text-underline {
    height: 2px;
    width: 100%;
    background: #6E5241;
}

.category-item:hover .category-svg {
    transform: translateX(5px);
}

@media (min-width: 415px) and (max-width: 920px) {
    .category-header {
        font-size: 40px;
    }

    .category-body-container {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 76px;
        margin-bottom: 170px;
    }

    .category-item {
        min-width: 294px;
    }
}

@media (min-width: 920px) {
    .breadcrumb {
        display: flex;
        margin-bottom: 99px;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
    }

    .category-header {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 118px;
    }

    .category-body-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: end;
        gap: 63px;
        margin-bottom: 400px;
    }

    .category-item {
        min-height: 229px;
    }

    .category-image-container {
        padding: 31px 53px 16px;
    }

    .category-image {
        min-height: 140px;
        height: 100%;
    }

    .category-text {
        font-size: 18px;
    }
}

/* Styles for .category */
@media (min-width: 920px) {
    .category {
        display: flex;
        justify-content: center;
    }

    .category-container {
        display: flex;
        flex-direction: column;
        max-width: 1440px;
        width: 100%;
        margin: 0;
    }
}
