footer {
    color: #FFF;
    font-family: Inter;
    background: var(--logo-brown, #674736);
}

.footer-container {
    padding: 48px 30px;
}

.footer-container.extended {
    padding: 48px 30px;
}

.header {
    margin-bottom: 60px;
    cursor: default;
}

.header-title {
    font-size: 22px;
    font-weight: 700;
}

.address-container {
    display: flex;
    gap: 5px;
}

.address-svg {
    width: 17.072px;
    height: 19px;
}

.address {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.body {
    display: flex;
    flex-direction: column;
}

.left-column, .right-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 70px;
}

.left-col-h, .right-col-h {
    margin: 0 0 2px;
    font-size: 20px;
    font-weight: 500;
    cursor: default;
}

.left-col-p, .right-col-p {
    margin: 0;
}

.left-col-nav {
    color: rgba(255, 255, 255, 0.90);
    font-size: 16px;
    text-decoration: none;
}

.right-col-text-container {
    display: flex;
    align-items: center;
    gap: 8px;
    column-gap: 10px;
}

.copyright {
    cursor: default;
}

.copy-text {
    font-size: 11px;
    font-weight: 400;
}

.right-col-text-container .right-col-p {
    margin: 0;
    cursor: pointer;
    color: white;
}

@media (min-width: 415px) and (max-width: 920px) {
    .footer-container {
        padding: 42px 49px 24px;
    }

    .body {
        flex-direction: row;
        gap: 225px;
    }

    .left-column, .right-column {
        gap: 20px;
        margin-bottom: 0;
    }

    .left-col-h, .right-col-h {
        margin: 0 0 12px;
    }

    .right-col-text-container .right-col-p.phone,
    .right-col-text-container .right-col-p.fax {
        margin: 0;
        cursor: default;
    }

    .copyright {
        margin-top: 38px;
    }

    .copy-text {
        font-size: 13px;
        opacity: 0.9;
    }
}

@media (min-width: 920px) {
    footer {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
        max-width: 1125px;
        margin: 0;
        padding: 42px 170px 22px;
    }

    .footer-container.extended {
        padding: 0 60px;
    }

    .body {
        flex-direction: row;
        gap: 100px;
    }

    .left-column, .right-column {
        gap: 20px;
        margin-bottom: 0;
    }

    .left-col-h, .right-col-h {
        margin: 0 0 11px;
    }

    .right-col-text-container {
        align-items: center;
    }

    .right-col-text-container .right-col-p.phone,
    .right-col-text-container .right-col-p.fax,
    .right-col-text-container .right-col-p.email {
        margin: 0;
        cursor: default;
        font-size: 15px;
        color: #FFF;
    }

    .copyright {
        margin-top: 50px;
    }

    .copy-text {
        font-size: 15px;
        opacity: 0.6;
    }
}

@media (min-width: 814px) and (max-width: 1300px) {
    .header {
        margin-bottom: 51px;
    }

    .header-title {
        font-size: 27px;
    }

    .address-container {
        gap: 6px;
    }

    .address-svg {
        width: 19px;
    }

    .address {
        font-size: 19px;
        font-weight: 500;
    }
}

@media (min-width: 1300px) {
    .header-title {
        font-size: 28px;
    }

    .address-container {
        align-items: center;
    }

    .address-svg {
        width: 19px;
    }

    .address {
        font-size: 20px;
        font-weight: 500;
    }
}
